import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import { Router, Redirect } from '@reach/router'
import { ThemeProvider } from 'styled-components'

// redux
import store from './redux/store'

// utils
import * as theme from './utils/theme'

// components
import { GlobalText } from './components'

// layouts
import DashboardLayout from './layout/Dashboard'
import OnboardingLayout from './layout/Onboarding'

// config
import l, { isDgmt, isEmploymentSite, isSef } from './config/localization'

// css imports
import './assets/fonts/fonts.css'

// routes
import {
  Terms,
  AboutUs,
  Careers,
  Campaigns,
  Categories,
  HomePage,
  SefHomePage,
  ExtrasPage,
  BusinessPricing,
  NonprofitPricing,
  EducationPricing,
  Pricing,
  ProcessDonation,
  ProcessPlatformPayment,
  PrivacyPolicy,
  NotFound,
  InstagramBioLinks,
  Login,
  Specs,
  Values,
  EmailConfirmation,
  OrgActivity,
  OrgBenefactors,
  OrgCauses,
  OrgFaqs,
  OrgGoal,
  OrgGoals,
  OrgWebsites,
  Probation,
  MagicLogin,
  PasswordReset,
  OzowDonationCompletion,
  OzowDonationFailure,
  PasswordSetup,
  Campaign,
  Opportunity,
  OrgDescription,
  RequestOrganisationMembership,
  ManageOrgInvite,
  ClaimOrganisation,
  ClaimOrganisationRequest,
  CompaniesMarketingPage,
  IndividualsMarketingPage,
  NonprofitsMarketingPage,
  FeedPage,
  User,
  DocsPage,
  UserChallenges,
  HostedOpps,
  JoinedOpps,
  UserOrgs,
  UserStories,
  Stories,
  // CliApiKey,
  ChallengeSearch,
  OppSearch,
  NonProfitSearch,
  OrgSearch,
  MoneyDonationConfirmation,
  GoodsDonationConfirmation,
  HostFeedback,
  VolunteerFeedback,
  PersonalGoodsDonationConfirmationRequest,
  PersonalMoneyDonationConfirmationRequest,
  OrgGoodsDonationConfirmationRequest,
  OrgGoodsDonationConfirmation,
  OrgMoneyDonationConfirmationRequest,
  OrgMoneyDonationConfirmation,
  ChallengeCheckin,
  Dashboard,
  DashboardPlatformEvents,
  DashboardEmailActivity,
  DashboardBoost,
  DashboardAssetUpload,
  DashboardInstagramBioLinks,
  DashboardOpportunities,
  DashboardContributions,
  DashboardChallenge,
  DashboardChallenges,
  DashboardNewChallenges,
  DashboardReceivedLikes,
  DashboardSubscriptions,
  DashboardNewOpportunities,
  DashboardOpportunity,
  DashboardOppActivity,
  DashboardOppPersonActivity,
  DashboardCampaign,
  DashboardCampaigns,
  DashboardNewCampaign,
  DashboardNotifications,
  DashboardOrganization,
  DashboardOrganisationVerify,
  DashboardOrganisationGoal,
  DashboardOrganisationGoals,
  DashboardOrganisationGoalNew,
  DashboardOrganisationGoalMilestoneNew,
  DashboardOrganisationGoalMilestone,
  DashboardOrganisationFaq,
  DashboardOrganisationFaqs,
  DashboardOrganisationFaqNew,
  DashboardOrganisationMembers,
  DashboardOrganizations,
  DashboardNewOrganization,
  DashboardNewPeople,
  // DashboardPersonalGoals,
  // DashboardPersonalGoalNew,
  DashboardStories,
  DashboardStoryNew,
  DashboardStory,
  DashboardPeople,
  DashboardNewTeam,
  DashboardTeams,
  DashboardTeam,
  DashboardSquads,
  DashboardBilling,
  DashboardBillingSelection,
  DashboardTeamMembers,
  DashboardTeamGroup,
  DashboardUpdateMembership,
  DashboardPersonActivity,
  DashboardAccount,
  DashboardUserAccountPrefs,
  DashboardNotFound,
  JoinBrowniePoints,
  CompleteOnboarding,
  Rules,
  ///
  /// DGMT routes
  ///
  DgmtDiscover,
  DgmtHome,
  DgmtMnos,
  DgmtPbos,
  NewPboForm,
  AdditionalWebsiteForm,
  PboApplication,
  PboApplications,
  MnoListingRequest,
  MnoListingRequests,
  ///
  /// Sef routes
  ///
  SefAttendance,
  SefSetParticipantAttendance,
  SipOnboardingForm,
  SefEmployeeOnboarding,
  SefEmploymentAgreement,
  SefBudgetCategory,
  SefBudgetCategories,
  SefBudgetCategoryNew,
  SefAdmins,
  SefPayroll,
  SefPayrollRequest,
  SefParticipants,
  SefParticipantDetails,
  SefSignaturePage,
  SefDistrict,
  SefDistrictHistory,
  SefDistricts,
  SefDistrictNew,
  SefManageEmployee,
  SefMuni,
  SefMunis,
  SefMuniNew,
  SefOutputArea,
  SefOutputAreas,
  SefOutputAreaNew,
  SefOutputIndicator,
  SefOutputIndicatorHistory,
  SefOutputIndicators,
  SefOutputIndicatorNew,
  SefOutput,
  SefOutputs,
  SefOutputNew,
  SefPartners,
  SefPartnerNew,
  SefPlace,
  SefPlaces,
  SefPlaceNew,
  SefPurchaseRequest,
  SefPurchaseRequests,
  SefPurchaseRequestNew,
  SefSupplier,
  SefSuppliers,
  SefSupplierNew,
  SefWard,
  SefWards,
  SefWardNew,
  SefWorkspace,
  SefWorkspaces,
  SefWorkspaceNew,
  SefWorksite,
  SefWorksiteHistory,
  SefWorksites,
  SefWorksiteEmployees,
  SefWorksiteNew,
  SefParticipantsUpload,
  SefEmploymentAgreementEdit,
  SefUploadHistoricalOutputs,
  SefEmploymentAgreements,
  SefEmploymentAgreementNew
} from './routes'

const isDev = process.env.NODE_ENV === 'development'

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Fragment>
        <GlobalText />
        <Router>
          {isDev && <SefEmploymentAgreement path="/employment" />}
          {isEmploymentSite && <SefEmploymentAgreement path="/" />}
          {isSef && <SefHomePage path="/" />}
          {isSef && <SipOnboardingForm path="/join-pilot" />}
          {!isDgmt && <HomePage path="/" />}

          {/* DGMT routes */}
          {isDgmt && <DgmtHome path="/" />}
          {isDgmt && <NewPboForm path="/new-pbo" />}
          {isDgmt && <AdditionalWebsiteForm path="/new-website" />}
          {isDgmt && <DgmtDiscover path="/discover" />}

          {/* Deprecate the /opportunities/:oppId routes */}
          {/* using ROUTE_OPPS_DEPRECATED because ROUTE_OPPS breaks GS */}
          <Redirect
            from={`${l('ROUTE_OPPS_DEPRECATED')}/:missionId`}
            to={`/${l('ROUTE_CAUSES')}/:missionId`}
            noThrow
          />

          {/* search */}
          <Redirect from={`search`} to={`/search/${l('ROUTE_OPPS')}`} noThrow />
          <ChallengeSearch path={`search/challenges`} />
          <NonProfitSearch path={`search/nonprofits`} />
          <OppSearch path={`search/${l('ROUTE_OPPS')}`} />
          <OrgSearch path={`search/companies`} />

          <Campaigns path={`${l('ROUTE_CAMPS')}`} />
          <Categories path="categories" />

          <DocsPage path="docs" />

          <CompaniesMarketingPage path="companies" />
          <NonprofitsMarketingPage path="nonprofits" />
          <IndividualsMarketingPage path="individuals" />

          {/* Entity pages */}
          <Campaign path={`${l('ROUTE_CAMPS')}/:campaignId`} />

          <OrgDescription path={`${l('ROUTE_ORGS')}/:orgName`} />
          <OrgActivity path={`${l('ROUTE_ORGS')}/:orgName/activity`} />
          <OrgBenefactors path={`${l('ROUTE_ORGS')}/:orgName/benefactors`} />
          <OrgBenefactors path={`${l('ROUTE_ORGS')}/:orgName/beneficiaries`} />
          <OrgCauses path={`${l('ROUTE_ORGS')}/:orgName/${l('OPPS')}`} />
          <OrgWebsites path={`${l('ROUTE_ORGS')}/:orgName/websites`} />
          <OrgFaqs path={`${l('ROUTE_ORGS')}/:orgName/faqs`} />
          <OrgGoals path={`${l('ROUTE_ORGS')}/:orgName/goals`} />
          <OrgGoal path={`${l('ROUTE_ORGS')}/:orgName/goals/:goalId`} />

          <User path="users/:userId" />

          {/* Opportunity pages */}
          <Opportunity path={`${l('ROUTE_CAUSES')}/:missionId`} />

          {/* Challenge checkins */}
          <ChallengeCheckin path={`${l('ROUTE_CAUSES')}/:missionId/checkin`} />

          {/* Volunteer feedback */}
          <VolunteerFeedback path={`${l('ROUTE_CAUSES')}/:missionId/feedback`} />

          {/* Host feedback */}
          <HostFeedback path={`${l('ROUTE_CAUSES')}/:missionId/host-feedback`} />

          {/* Mission page donation confirmation requests */}

          {/* Mission page - Personal Money Donation feedback */}
          <PersonalMoneyDonationConfirmationRequest
            path={`${l('ROUTE_CAUSES')}/:missionId/donations/:confirmationId`}
          />

          {/* Mission page - Personal Goods Donation feedback */}
          <PersonalGoodsDonationConfirmationRequest
            path={`${l('ROUTE_CAUSES')}/:missionId/goods-donations/:confirmationId`}
          />

          {/* Mission page - Org Goods Donation feedback */}
          <OrgGoodsDonationConfirmationRequest
            path={`${l('ROUTE_CAUSES')}/:missionId/org-goods-donations/:confirmationId`}
          />

          {/* Mission page - Org Money Donation feedback */}
          <OrgMoneyDonationConfirmationRequest
            path={`${l('ROUTE_CAUSES')}/:missionId/org-donations/:confirmationId`}
          />

          {/* Mission page donation confirmations */}

          {/* Mission page - confirm personal Money Donation */}
          <MoneyDonationConfirmation
            path={`${l('ROUTE_CAUSES')}/:missionId/confirm-donation/:confirmationId`}
          />

          {/* Mission page - confirm Org Money Donation */}
          <OrgMoneyDonationConfirmation
            path={`${l('ROUTE_CAUSES')}/:missionId/org-confirm-donation/:confirmationId`}
          />

          {/* Mission page - confirm Goods Donation */}
          <GoodsDonationConfirmation
            path={`${l('ROUTE_CAUSES')}/:missionId/confirm-goods-donation/:confirmationId`}
          />

          {/* Mission page - confirm Org Goods Donation */}
          <OrgGoodsDonationConfirmation
            path={`${l('ROUTE_CAUSES')}/:missionId/confirm-org-goods-donation/:confirmationId`}
          />

          {/* Nonprofit page page donation confirmation requests */}

          {/* Nonprofit page - Personal Money Donation feedback */}
          <PersonalMoneyDonationConfirmationRequest
            path={`${l('ROUTE_ORGS')}/:organisationName/donations/:confirmationId`}
          />

          {/* Nonprofit page - Org Money Donation feedback */}
          <OrgMoneyDonationConfirmationRequest
            path={`${l('ROUTE_ORGS')}/:organisationName/org-donations/:confirmationId`}
          />

          {/* Nonprofit page - confirm personal Money Donation */}
          <MoneyDonationConfirmation
            path={`${l('ROUTE_ORGS')}/:organisationName/confirm-donation/:confirmationId`}
          />

          {/* Nonprofit page - confirm Org Money Donation */}
          <OrgMoneyDonationConfirmation
            path={`${l('ROUTE_ORGS')}/:organisationName/org-confirm-donation/:confirmationId`}
          />

          {/* claim org */}
          <ManageOrgInvite path={`${l('ROUTE_ORGS')}/:orgName/manage-invite`} />
          <RequestOrganisationMembership path={`${l('ROUTE_ORGS')}/:orgName/join`} />
          <ClaimOrganisationRequest path={`${l('ROUTE_ORGS')}/:orgName/claim`} />
          <ClaimOrganisation path={`claim-organisation`} />

          {/* onboarding */}
          <JoinBrowniePoints path="join" />

          <OnboardingLayout path="onboarding">
            <CompleteOnboarding path="/" />
          </OnboardingLayout>

          {/* feedback */}
          <VolunteerFeedback path={`${l('ROUTE_OPP')}-feedback`} />
          <HostFeedback path="host-feedback" />

          {/* auth */}
          <MagicLogin path="magic" />
          <Login path="login" />
          <PasswordReset path="reset-password" />
          <PasswordSetup path="set-password" />
          <EmailConfirmation path="confirm-email" />
          <Probation path="probation" />
          <Redirect from="dashboard/login" to="login" noThrow />

          {/* stories */}
          <FeedPage path="feed" />
          <Stories path="stories/:storyId" />

          {/* ozow */}
          <OzowDonationCompletion path="/ozow/success" />
          <OzowDonationFailure path="/ozow/fail" />

          {/* Sef employee routes */}
          <SefEmployeeOnboarding path="employees/onboarding" />

          <DashboardLayout path="dashboard">
            <Dashboard path="/" />

            {/* Deprecate the /dashboard/opportunities routes */}
            {/* Deprecate the /dashboard/opportunities/:oppId routes */}
            {/* using ROUTE_OPPS_DEPRECATED because ROUTE_OPPS breaks GS */}
            <Redirect
              from={`${l('ROUTE_OPPS_DEPRECATED')}`}
              to={`/dashboard/${l('ROUTE_CAUSES')}`}
              noThrow
            />
            <Redirect
              from={`${l('ROUTE_OPPS_DEPRECATED')}/new`}
              to={`/dashboard/${l('ROUTE_CAUSES')}/new`}
              noThrow
            />
            <Redirect
              from={`${l('ROUTE_OPPS_DEPRECATED')}/:missionId`}
              to={`/dashboard/${l('ROUTE_CAUSES')}/:missionId`}
              noThrow
            />
            <Redirect
              from={`${l('ROUTE_OPPS_DEPRECATED')}/:missionId/stories`}
              to={`/dashboard/${l('ROUTE_CAUSES')}/:missionId/stories`}
              noThrow
            />
            <Redirect
              from={`${l('ROUTE_CAUSES')}/:missionId/activity`}
              to={`/dashboard/${l('ROUTE_CAUSES')}/:missionId/stories`}
              noThrow
            />
            <Redirect
              from={`${l('ROUTE_OPPS_DEPRECATED')}/:missionId/stories/:applicationId`}
              to={`/dashboard/${l('ROUTE_CAUSES')}/:missionId/stories/:applicationId`}
              noThrow
            />
            <Redirect
              from={`${l('ROUTE_OPPS_DEPRECATED')}/:missionId/activity/:applicationId`}
              to={`/dashboard/${l('ROUTE_CAUSES')}/:missionId/stories/:applicationId`}
              noThrow
            />

            <DashboardEmailActivity path="email-activity" />
            <DashboardAccount path="/account" />
            <DashboardUserAccountPrefs path="account/notification-preferences" />

            {/* <CliApiKey path="account/cli" /> */}
            {false && <Rules path="rules" />}
            <DashboardBoost path="boosts" />
            <DashboardBillingSelection path="billing" />
            <DashboardBilling path="billing/:orgId" />
            <DashboardPlatformEvents path="platform-events" />
            <DashboardAssetUpload path="assets" />
            <DashboardInstagramBioLinks path="instagram-bio-links" />

            <DashboardOpportunities path={l('ROUTE_CAUSES')} />
            <DashboardNewOpportunities path={`${l('ROUTE_CAUSES')}/new`} />
            <DashboardOpportunity path={`${l('ROUTE_CAUSES')}/:missionId`} />
            <DashboardOppActivity path={`${l('ROUTE_CAUSES')}/:missionId/stories`} />
            <DashboardOppPersonActivity
              path={`${l('ROUTE_CAUSES')}/:missionId/stories/:applicationId`}
            />

            <DashboardUpdateMembership path="manage-person/:personId" />
            <DashboardPeople path="people" />
            <DashboardPersonActivity path="people/:personId" />
            <DashboardNewPeople path="people/new" />

            <DashboardCampaigns path={l('ROUTE_CAMPS')} />
            <DashboardNewCampaign path={`${l('ROUTE_CAMPS')}/new`} />
            <DashboardCampaign path={`${l('ROUTE_CAMPS')}/:campaignId`} />

            <DashboardOrganizations path={`${l('ROUTE_ORGS')}`} />
            <DashboardNewOrganization path={`${l('ROUTE_ORGS')}/new`} />
            <DashboardOrganization path={`${l('ROUTE_ORGS')}/:organisationId`} />

            <DashboardOrganisationMembers path={`${l('ROUTE_ORGS')}/:organisationId/members`} />
            <DashboardOrganisationVerify path={`${l('ROUTE_ORGS')}/:organisationId/verify`} />

            <DashboardOrganisationFaq path={`${l('ROUTE_ORGS')}/:organisationId/faqs/:faqId`} />
            <DashboardOrganisationFaqs path={`${l('ROUTE_ORGS')}/:organisationId/faqs`} />
            <DashboardOrganisationFaqNew path={`${l('ROUTE_ORGS')}/:organisationId/faqs/new`} />

            <DashboardOrganisationGoal path={`${l('ROUTE_ORGS')}/:organisationId/goals/:goalId`} />
            <DashboardOrganisationGoals path={`${l('ROUTE_ORGS')}/:organisationId/goals`} />
            <DashboardOrganisationGoalNew path={`${l('ROUTE_ORGS')}/:organisationId/goals/new`} />

            <DashboardOrganisationGoalMilestoneNew
              path={`${l('ROUTE_ORGS')}/:organisationId/goals/:goalId/milestones/new`}
            />
            <DashboardOrganisationGoalMilestone
              path={`${l('ROUTE_ORGS')}/:organisationId/goals/:goalId/milestones/:milestoneId`}
            />

            <DashboardNotifications path="notifications" />
            <DashboardReceivedLikes path="likes" />
            <DashboardContributions path="contributions" />

            <DashboardChallenges path="challenges" />
            <DashboardChallenges path="challenges/created" />
            <DashboardChallenge path="challenges/:challengeId" />
            <DashboardNewChallenges path="challenges/new" />

            <DashboardSubscriptions path="memberships" />

            {/* search codebase for required changes when changing this: TEMP_DISABLED_GOALS */}
            {/* <DashboardPersonalGoals path="goals" /> */}
            {/* <DashboardPersonalGoalNew path="goals/new" /> */}

            <DashboardStories path="stories" />
            <DashboardStoryNew path="stories/new" />
            <DashboardStory path="stories/:storyId" />

            <DashboardSquads path="squads" />

            <DashboardTeams path="teams" />
            <DashboardNewTeam path="teams/new" />
            <DashboardTeamMembers path="teams/:teamId" />
            <DashboardTeam path="teams/:teamId/edit" />
            <DashboardTeamGroup path="team-groups/:teamGroupId" />

            {/* Dgmt dashboard routes */}
            {(isSef || isDgmt) && <DashboardStoryNew path="feedback/new" />}

            {isDgmt && <PboApplications path="/sir-applications" />}
            {isDgmt && <PboApplication path="sir-applications/:pboApplicationId" />}
            {isDgmt && <DashboardStories path="feedback" />}
            {isDgmt && <DashboardStory path="feedback/:storyId" />}
            {isDgmt && <MnoListingRequest path="zero-rated-websites/:listingRequestId" />}
            {isDgmt && <MnoListingRequests path="zero-rated-websites" />}
            {isDgmt && <DgmtMnos path="mnos" />}
            {isDgmt && <DgmtPbos path="pbos" />}

            {/* Sef dashboard routes */}
            <SefUploadHistoricalOutputs path="historical-outputs" />
            <SefEmploymentAgreementEdit path="agreements/:agreementTemplateId" />
            <SefEmploymentAgreements path="agreements" />
            <SefEmploymentAgreementNew path="agreements/new" />
            <SefSetParticipantAttendance path="attendance/participants/:participantId" />
            <SefAttendance path="attendance" />
            <SefBudgetCategory path="budget-categories/:budgetCategoryId" />
            <SefBudgetCategories path="budget-categories" />
            <SefBudgetCategoryNew path="budget-categories/new" />
            <SefPayroll path="payroll" />
            <SefPayrollRequest path="payroll-requests/:paymentRequestId" />
            <SefParticipants path="participants" />
            <SefParticipantsUpload path="participants/upload" />
            <SefParticipantDetails path="participants/:participantId" />
            <SefAdmins path="admins" />
            <SefSignaturePage path="signature" />
            <SefDistrict path="districts/:districtId" />
            <SefDistrictHistory path="districts/:districtId/history" />
            <SefDistricts path="districts" />
            <SefDistrictNew path="districts/new" />
            <SefManageEmployee path="worksites/:worksiteId/employees/:employeeId" />
            <SefMuni path="municipalities/:municipalityId" />
            <SefMunis path="municipalities" />
            <SefMuniNew path="municipalities/new" />
            <SefOutputArea path="output-areas/:outputAreaId" />
            <SefOutputs path="outputs" />
            <SefOutput path="outputs/:outputId" />
            <SefOutputNew path="outputs/new" />
            <SefOutputAreas path="output-areas" />
            <SefOutputAreaNew path="output-areas/new" />
            <SefOutputIndicator path="output-indicators/:outputIndicatorId" />
            <SefOutputIndicatorHistory path="output-indicators/:outputIndicatorId/history" />
            <SefOutputIndicators path="output-indicators" />
            <SefOutputIndicatorNew path="output-indicators/new" />
            <SefPartners path="partners" />
            <SefPartnerNew path="partners/new" />
            <SefPlace path="places/:placeId" />
            <SefPlaces path="places" />
            <SefPlaceNew path="places/new" />
            <SefPurchaseRequest path="purchase-requests/:purchaseRequestId" />
            <SefPurchaseRequests path="purchase-requests" />
            <SefPurchaseRequestNew path="purchase-requests/new" />
            <SefSupplier path="suppliers/:supplierId" />
            <SefSuppliers path="suppliers" />
            <SefSupplierNew path="suppliers/new" />
            <SefWard path="wards/:wardId" />
            <SefWards path="wards" />
            <SefWardNew path="wards/new" />
            <SefWorkspace path="workspaces/:workspaceId" />
            <SefWorkspaces path="workspaces" />
            <SefWorkspaceNew path="workspaces/new" />
            <SefWorksite path="worksites/:worksiteId" />
            <SefWorksiteHistory path="worksites/:worksiteId/history" />
            <SefWorksites path="worksites" />
            <SefWorksiteEmployees path="worksites/:worksiteId/employees" />
            <SefWorksiteNew path="worksites/new" />

            {/* 404 */}
            <DashboardNotFound path=":misc" />
          </DashboardLayout>

          {/* pricing */}
          <Pricing path="pricing" />
          <NonprofitPricing path="pricing/nonprofits" />
          <BusinessPricing path="pricing/business" />
          <EducationPricing path="pricing/education" />
          <ExtrasPage path="extras" />

          {/* payment processing for platform subscriptions */}
          <ProcessPlatformPayment path="process-payment" />
          {/* payment processing for once-off donation payments and recurring donations subscriptions */}
          <ProcessDonation path="process-donation" />

          <Specs path="specs" />
          <Values path="values" />
          <Terms path="terms" />
          <AboutUs path="about-us" />
          {false && <Careers path="careers" />}
          <PrivacyPolicy path="privacy" />
          <InstagramBioLinks path="ig-bio" />

          {/* Last so any username cannot override existing routes */}
          <User path=":username" />
          <UserStories path=":username/shared-stories" />
          <UserOrgs path=":username/nonprofits" />
          <UserChallenges path="/:username/joined-challenges" />
          <HostedOpps path=":username/hosted" />
          <JoinedOpps path=":username/joined" />

          {/* 404 - will only reach here for non-flat routes (/foo/bar) */}
          {/* All flat routes (/foo) get caught in the above User path */}
          <NotFound default />
        </Router>
      </Fragment>
    </Provider>
  </ThemeProvider>
)

export default App
