function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
import React from 'react';
var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    height: "100px",
    width: "100px",
    fill: "#379B75",
    xmlnsDc: "http://purl.org/dc/elements/1.1/",
    xmlnsCc: "http://creativecommons.org/ns#",
    xmlnsRdf: "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
    xmlnsSvg: "http://www.w3.org/2000/svg",
    xmlnsSodipodi: "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd",
    xmlnsInkscape: "http://www.inkscape.org/namespaces/inkscape",
    version: 1.1,
    x: "0px",
    y: "0px",
    viewBox: "0 0 100 100"
  }, props), React.createElement("g", {
    transform: "translate(0,-952.36218)"
  }, React.createElement("path", {
    d: "m 37,956.80851 -34.9999999,16 L 12.625,985.21476 c 11.97913,-5.32301 23.95833,-10.64585 35.9375,-15.96875 z m 26,0 -11.5625,12.4375 c 11.97908,5.32311 23.95848,10.64549 35.9375,15.96875 L 98,972.80851 z M 50,972.99601 18.9375,986.80851 50,1000.5898 81.0625,986.80851 z m -37.34375,15.375 -10.6562499,12.43759 34.9999999,16 11.5625,-12.4688 C 36.6162,998.96746 24.5854,993.78097 12.65625,988.37101 z m 74.6875,0 C 75.38523,993.71603 63.40787,999.02076 51.4375,1004.3398 L 63,1016.8086 l 35,-16 z M 14,1010.6836 l 0,22.1073 34,15.125 0,-37.0761 -8.0625,8.6875 -1.96875,2.0938 -2.625,-1.1875 z m 72,0 -21.34375,9.75 -2.625,1.1875 -1.96875,-2.0938 -8.0625,-8.6875 0,37.0449 34,-15.0938 z",
    style: {
      "color": "#000000",
      "enableBackground": "accumulate"
    },
    fill: "#379B75",
    stroke: "none",
    marker: "none",
    visibility: "visible",
    display: "inline",
    overflow: "visible"
  })));
};
export default __webpack_public_path__ + "static/media/goods_icon.cd1a1e19.svg";
export { SvgComponent as ReactComponent };