'use strict'

// Avoid using 0 (zero) values in this file, as JavaScript reads 0 as falsey

import l from 'config/localization'
import { getLocalStorageValues } from 'utils/localStorageManager'

const isProd = process.env.REDUX_ENV === 'production'
const isStaging = process.env.REDUX_ENV === 'staging'
const GOOD_ECONOMY_CAMPAIGN_ID = isProd ? 71 : isStaging ? 6 : 71
const WHAT_MATTERS_CAMPAIGN_ID = isProd ? 73 : isStaging ? 6 : 73

// assets
import { ReactComponent as PrivateIcon } from 'assets/images/svgs/icon-public-opp.svg'
import { ReactComponent as PublicIcon } from 'assets/images/svgs/icon-private-opp.svg'

import DonationIcon from 'assets/images/cause-type-icons/money_icon.svg'
import GoodsIcon from 'assets/images/cause-type-icons/goods_icon.svg'
import UserIcon from 'assets/images/user.svg'
import VolunteerIcon from 'assets/images/cause-type-icons/volunteer_icon.svg'

const subscriptionPrices = [
  {
    tier: 'SELF',
    pricing: {
      premium: { yearly: { m: 'R286', y: 'R3,436' }, monthly: 'R344' },
      enterprise: { yearly: { m: 'R3,392', y: 'R40,710' }, monthly: 'Available on a yearly plan' }
    }
  },
  {
    tier: 'NPO',
    pricing: {
      premium: { yearly: { m: 'R286', y: 'R3,436' }, monthly: 'R344' },
      enterprise: { yearly: { m: 'R3,392', y: 'R40,710' }, monthly: 'Available on a yearly plan' }
    }
  },
  {
    tier: 'REL',
    pricing: {
      premium: { yearly: { m: 'R286', y: 'R3,436' }, monthly: 'R344' },
      enterprise: { yearly: { m: 'R3,392', y: 'R40,710' }, monthly: 'Available on a yearly plan' }
    }
  },
  {
    tier: 'SCH',
    pricing: {
      premium: { yearly: { m: 'R631', y: 'R7,576' }, monthly: 'R746' },
      enterprise: { yearly: { m: 'R4,542', y: 'R54,510' }, monthly: 'Available on a yearly plan' }
    }
  },
  {
    tier: 'BUS',
    pricing: {
      premium: { yearly: { m: 'R1,091', y: 'R13,096' }, monthly: 'R1,436' },
      enterprise: { yearly: { m: 'R6,842', y: 'R82,110' }, monthly: 'Available on a yearly plan' }
    }
  }
]

const getGoalTypes = () => {
  return {
    MONEY: 'Money',
    GOODS: 'Goods',
    VOLUNTEERING: 'Volunteering',
    CHALLENGE: 'Challenge',
    UNAVAILABLE_DONATION_OPTIONS: 'N/A'
  }
}

const getGoalTypeNames = () => {
  return { Money: 'Donations', Goods: 'Goods', Volunteering: 'Volunteers', Challenge: 'Challenges' }
}

const getGoalTypeCtas = () => {
  return {
    Money: 'Donate',
    Goods: 'Donate goods',
    Volunteering: 'Volunteer',
    Challenge: 'Join challenge'
  }
}

const getGoalTypeDeliverables = () => {
  return {
    Money: 'rands',
    Goods: 'goods',
    Volunteering: 'volunteers',
    Challenge: 'participants'
  }
}

const goalTypes = getGoalTypes()
const goalTypeCtas = getGoalTypeCtas()
const goalTypeNames = getGoalTypeNames()
const goalTypeDeliverables = getGoalTypeDeliverables()

const FORM_GOAL_TYPES = [
  {
    id: `${goalTypes.MONEY}`,
    name: goalTypeNames[goalTypes.MONEY],
    icon: DonationIcon,
    padding: '5px 3px 7px 2px'
  },
  {
    id: `${goalTypes.GOODS}`,
    name: goalTypeNames[goalTypes.GOODS],
    icon: GoodsIcon,
    padding: '5px 4px 6px 2px'
  },
  {
    id: `${goalTypes.VOLUNTEERING}`,
    name: goalTypeNames[goalTypes.VOLUNTEERING],
    icon: VolunteerIcon,
    padding: '3px 5px 6px 3px'
  }
]

const BBBEE_LEVELS = [
  { value: 1, label: 'Level 1' },
  { value: 2, label: 'Level 2' },
  { value: 3, label: 'Level 3' },
  { value: 4, label: 'Level 4' }
]

const BENEFACTOR_TYPES = {
  USER: 'User',
  ORG: 'Organisation'
}

const BENEFICIARY_TYPES = {
  ANY: 'any',
  USER: 'User',
  NPO_ORG: 'Nonprofit',
  SCHOOL: 'School'
}

const CREATOR_CONTEXTS = {
  USER: 'User',
  ORG: 'Organisation'
}

const DGMT_PBO_APPLICATION_STATUSES = {
  COMPLETED: 'Completed',
  DRAFT: 'Draft',
  PENDING: 'Pending',
  INVALID: 'Invalid'
}

const DGMT_PBO_APPLICATION_WEBSITE_STATUSES = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  INVALID: 'Invalid'
}

const DGMT_ZERO_RATED_LISTING_STATUSES = {
  INVALID: 'Invalid',
  PENDING: 'Pending',
  ZERO_RATED: 'ZeroRated'
}

const DGMT_PBO_APPLICATION_STATUS_FORM_OPTS = [
  { label: 'Completed', value: DGMT_PBO_APPLICATION_STATUSES.COMPLETED },
  { label: 'Draft', value: DGMT_PBO_APPLICATION_STATUSES.DRAFT },
  { label: 'Pending', value: DGMT_PBO_APPLICATION_STATUSES.PENDING },
  { label: 'Invalid', value: DGMT_PBO_APPLICATION_STATUSES.INVALID }
]

const DGMT_ZERO_RATED_LISTING_STATUS_FORM_OPTS = [
  { label: 'Zero rated', value: DGMT_ZERO_RATED_LISTING_STATUSES.ZERO_RATED },
  { label: 'Pending', value: DGMT_ZERO_RATED_LISTING_STATUSES.PENDING },
  { label: 'Invalid', value: DGMT_ZERO_RATED_LISTING_STATUSES.INVALID }
]

const NPO_VERIFICATION_STATUSES = {
  REQUESTED: 'Requested',
  UNVERIFIED: 'Unverified',
  VERIFIED: 'Verified'
}

const DGMT_PBO_VERIFICATION_STATUS_FORM_OPTS = [
  { label: NPO_VERIFICATION_STATUSES.REQUESTED, value: NPO_VERIFICATION_STATUSES.REQUESTED },
  { label: NPO_VERIFICATION_STATUSES.UNVERIFIED, value: NPO_VERIFICATION_STATUSES.UNVERIFIED },
  { label: NPO_VERIFICATION_STATUSES.VERIFIED, value: NPO_VERIFICATION_STATUSES.VERIFIED }
]

const DGMT_PBO_APPLICATION_WEBSITE_STATUS_FORM_OPTS = [
  { label: 'Approved', value: DGMT_PBO_APPLICATION_WEBSITE_STATUSES.APPROVED },
  { label: 'Pending', value: DGMT_PBO_APPLICATION_WEBSITE_STATUSES.PENDING },
  { label: 'Invalid', value: DGMT_PBO_APPLICATION_WEBSITE_STATUSES.INVALID }
]

const DGMT_ZERO_RATING_STATUSES = {
  Invalid: 'Invalid',
  Pending: 'Pending',
  PreZeroRated: 'Zero-rated',
  ZeroRated: 'Zero-rated'
}

const DGMT_ZERO_RATING_STATUS_FORM_OPTS = [
  { label: 'Zero rated', value: 'ZeroRated' },
  { label: 'Pending', value: DGMT_PBO_APPLICATION_STATUSES.PENDING },
  { label: 'Invalid', value: DGMT_PBO_APPLICATION_STATUSES.INVALID }
]

const GOODS_DONATION_STATUSES = {
  COMMITTED: 'Committed',
  CONFIRMED: 'Confirmed',
  PENDING_DONATION: 'PendingDonation',
  PENDING_DONATION_APPROVAL: 'PendingDonationApproval',
  REJECTED: 'Rejected',
  WITHDREW: 'Withdrew'
}

const PAYROLL_REQUEST_STATUSES = {
  CANCELLED: 'Cancelled',
  PENDING_DISBURSEMENT_APPROVAL: 'PendingDisbursementApproval',
  PENDING_DISBURSEMENT_EXECUTION: 'PendingDisbursementExecution',
  REJECTED: 'Rejected',
  EXECUTED: 'Executed'
}

const MAIL_DELIVERY_STATUSES = {
  SENT: 'Sent',
  DELIVERED: 'Delivered',
  OPENED: 'Opened',
  CLICKED: 'Clicked',
  FAILED: 'Failed'
}

const SEF_PURCHASE_REQUEST_STATUSES = {
  SELF_ARCHIVED: 'Archived',
  SUBMITTED: 'Submitted',
  DENIED: 'Denied',
  APPROVED: 'Approved',
  PAID: 'Paid',
  COMPLETED: 'Completed'
}

const SEF_PURCHASE_REQUEST_STATUS_FORM_OPTS = [
  { label: 'Submitted', value: SEF_PURCHASE_REQUEST_STATUSES.SUBMITTED },
  { label: 'Denied', value: SEF_PURCHASE_REQUEST_STATUSES.DENIED },
  { label: 'Approved', value: SEF_PURCHASE_REQUEST_STATUSES.APPROVED },
  { label: 'Paid/Pending delivery', value: SEF_PURCHASE_REQUEST_STATUSES.PAID },
  { label: 'Completed', value: SEF_PURCHASE_REQUEST_STATUSES.COMPLETED }
]

const SEF_INDICATOR_NUMBER_TYPES = {
  ABSOLUTE: 'Absolute',
  RATIO: 'Ratio',
  PERCENTAGE: 'Percentage',
  MONEY: 'Money',
  TEXT: 'Text',
  MULTI_OPTION_TEXT_ONLY: 'MultiOptionTextOnly',
  MULTI_OPTION_NUMBER_ONLY: 'MultiOptionNumberOnly',
  MULTI_OPTION_MIXED: 'MultiOptionMixed'
}

const PLATFORM_EVENTS = {
  ACCOUNT_ACTIVATION_CONFIRMATION: 'Account Activation Confirmation',
  ACCOUNT_ACTIVATION_REQUEST: 'Account Activation Request',
  ADMIN_INVITE: 'Admin Opportunity Invitation',
  BENEFICIARY_SUPPORTER_SIGNUP_NOTIF: 'Beneficiary Supporter Signup Notification',
  CHALLENGE_CREATED: 'Challenge Created',
  COMMUNITY_BOOST_REQUEST: 'Boost Request',
  EMAIL_CONFIRMED: 'Email Confirmed',
  CAMP_CREATED: 'Campaign Created',
  CAMP_DELETED: 'Campaign Deleted',
  CAMP_UPDATED: 'Campaign Updated',
  CLAIM_ORGANISATION_REQUEST: 'Organisation Claim Request',
  HOST_SIGN_UP: 'Host Signup Notification',
  MAGIC_LOGIN: 'Magic Login Request',
  MENTOR_INVITE: 'Mentor Invite',
  NPO_VERIFY_REQUEST: 'Nonprofit Verification Requested',
  OPP_CHECKIN: 'Volunteer Opportunity Checked In',
  OPP_CREATED: 'Opportunity Created',
  OPP_DELETED: 'Opportunity Deleted',
  OPP_DELETED_NOTIF: 'Volunteer Opportunity Deleted Notification',
  OPP_HOST_CHECKIN: 'Host Opportunity Checked In',
  OPP_SIGN_UP: 'Volunteer Opportunity Signup',
  OPP_UPDATED: 'Opportunity Updated',
  OPP_UPDATED_NOTIF: 'Volunteer Opportunity Updated Notification',
  ORG_CREATED: 'Organisation Created',
  OZOW_ACCOUNT_ACTIVATED: 'Ozow Account Activated',
  OZOW_ACCOUNT_REQUESTED: 'Ozow Account Requested',
  PASSWORD_RESET_SUCCESS: 'Password Reset Successful',
  PRINCIPAL_INVITE: 'Principal Invite',
  PRINCIPAL_ONBOARDING_REMINDER: 'Principal Onboarding Reminder',
  ROUND_CONFIRMATION: 'Round Confirmation',
  ROUND_REMINDER: 'Round Reminder',
  ROUND_UPDATE: 'Round Update',
  SUBJECT_REGISTRATION_CONFIRM: 'Subject Registration Confirmation',
  SUPERADMIN_OPPORTUNITY_CREATED: 'Superadmin Cause Created Notification',
  SUPERADMIN_INVITE: 'Superadmin Invitation',
  TRACK_MEMBER_INVITE: 'Member Invitation',
  TRACK_PASSWORD_RESET: 'Password Reset Request',
  OPP_CHECKIN_PROMPT: 'Volunteer Opportunity Checkin Prompt',
  OPP_REMINDER: 'Volunteer Opportunity Reminder Notification',
  HOST_CHECKIN_PROMPT: 'Host Checkin Prompt',
  NPO_VERIFY_ACTIVATED: 'Nonprofit Verification Activated',
  SUBSCRIPTION_UPDATED: 'Subscription Updated',
  WORKSHOP_CONFIRMATION: 'Workshop Confirmation',
  WORKSHOP_REMINDER: 'Workshop Reminder',
  WORKSHOP_UPDATE: 'Workshop Update'
}

const SEF_BANK_ACCOUNT_TYPES = [
  { label: 'Cheque', value: 'Cheque' },
  { label: 'Savings', value: 'Savings' }
]

const SEF_BANK_OPTS = [
  { label: 'ABSA', value: 'ABSA' },
  { label: 'African Bank', value: 'African Bank' },
  { label: 'Bank of Athens', value: 'Bank of Athens' },
  { label: 'Bidvest Bank', value: 'Bidvest Bank' },
  { label: 'Capitec', value: 'Capitec' },
  { label: 'Discovery Bank', value: 'Discovery Bank' },
  { label: 'Finbond Mutual Bank', value: 'Finbond Mutual Bank' },
  { label: 'Fnb', value: 'Fnb' },
  { label: 'Gindrod Bank (Easypay)', value: 'Gindrod Bank (Easypay)' },
  { label: 'Ithala', value: 'Ithala' },
  { label: 'Nedbank', value: 'Nedbank' },
  { label: 'Old Mutual Bank', value: 'Old Mutual Bank' },
  { label: 'Post Bank', value: 'Post Bank' },
  { label: 'SASFin Bank', value: 'SASFin Bank' },
  { label: 'Standard Bank', value: 'Standard Bank' },
  { label: 'Tyme Bank', value: 'Tyme Bank' },
  { label: 'Ubank', value: 'Ubank' },
  { label: 'Wizzit', value: 'Wizzit' }
]

const NOTIFICATION_LABELS = {
  CHALLENGE_CHECKIN_REMINDER: 'ChallengeCheckinReminder',
  GOODS_DONATION_CONFIRMATION_APPROVED: 'GoodsDonationConfirmationApproved',
  GOODS_DONATION_CONFIRMATION_REJECTED: 'GoodsDonationConfirmationRejected',
  GOODS_DONATION_CONFIRMATION_REQUEST: 'GoodsDonationConfirmationRequest',
  LIKED_STORY: 'LikedStory',
  MONEY_DONATION_CONFIRMATION_APPROVED: 'MoneyDonationConfirmationApproved',
  MONEY_DONATION_CONFIRMATION_REJECTED: 'MoneyDonationConfirmationRejected',
  MONEY_DONATION_CONFIRMATION_REQUEST: 'MoneyDonationConfirmationRequest',
  ORG_JOIN_INVITE: 'OrgJoinInvite',
  ORG_JOIN_REQUEST: 'OrgJoinRequest',
  PAUSED_DONATION_SUBSCRIPTION: 'PausedDonationSubscription',
  STORY_BENEFICIARY_TAG_REQUEST: 'StoryBeneficiaryTagRequest',
  // DGMT
  DGMT_NOTIFY_MNO_FOR_LISTING_REQUEST: 'DgmtNotifyMnoForListingRequest',
  DGMT_PBO_VETTING_REMINDER: 'DgmtPboVettingReminder',
  DGMT_SIR_APPLICATION_APPROVED: 'DgmtSirApplicationApproved',
  DGMT_SIR_APPLICATION_INVALID: 'DgmtSirApplicationInvalid',
  DGMT_SIR_APPLICATION_SUBMITTED: 'DgmtSirApplicationSubmitted',
  DGMT_SIR_APPLICATION_UPDATED: 'DgmtSirApplicationUpdated'
}

const SEF_DOCUMENT_TYPES = {
  PROOF_OF_PAYMENT: 'ProofOfPayment',
  PURCHASE_REQUEST_INVOICE: 'PurchaseRequestInvoice',
  PURCHASE_REQUEST_QUOTE: 'PurchaseRequestQuote'
}

const SEF_EMPLOYMENT_START_PERIODS = [
  { label: 'October 2022', value: 'Month 1' },
  { label: 'November 2022', value: 'Month 2' },
  { label: 'December 2022', value: 'Month 3' },
  { label: 'January 2023', value: 'Month 4' },
  { label: 'February 2023', value: 'Month 5' },
  { label: 'March 2023', value: 'Month 6' },
  { label: 'April 2023', value: 'Month 7' },
  { label: 'May 2023', value: 'Month 8' },
  { label: 'June 2023', value: 'Month 9' },
  { label: 'July 2023', value: 'Month 10' },
  { label: 'August 2023', value: 'Month 12' },
  { label: 'September 2023', value: 'Month 13' },
  { label: 'October 2023', value: 'Month 14' },
  { label: 'November 2023', value: 'Month 15' },
  { label: 'December 2023', value: 'Month 16' },
  { label: 'January 2024', value: 'Month 17' },
  { label: 'February 2024', value: 'Month 18' },
  { label: 'March 2024', value: 'Month 19' },
  { label: 'April 2024', value: 'Month 20' },
  { label: 'May 2024', value: 'Month 21' },
  { label: 'June 2024', value: 'Month 22' },
  { label: 'July 2024', value: 'Month 23' },
  { label: 'August 2024', value: 'Month 24' },
  { label: 'September 2024', value: 'Month 25' },
  { label: 'October 2024', value: 'Month 26' },
  { label: 'November 2024', value: 'Month 27' },
  { label: 'December 2024', value: 'Month 28' },
  { label: 'January 2025', value: 'Month 29' },
  { label: 'February 2024', value: 'Month 30' }
]

const SEF_GENDER_OPTIONS = [
  { label: 'Female', value: 'Female' },
  { label: 'Male', value: 'Male' }
]

const SEF_GOVT_GRANT_OPTIONS = [
  { label: 'None', value: 'No' },
  { label: 'Social', value: 'Social' },
  { label: 'Disability', value: 'Disability' },
  { label: 'Child Support', value: 'Child Support' },
  { label: 'Other', value: 'Other' }
]

const SEF_NATIONALITY_OPTIONS = [
  { label: 'RSA', value: 'RSA' },
  { label: 'Non RSA', value: 'NonRSA' }
]

const SEF_RACE_OPTIONS = [
  { label: 'African', value: 'African' },
  { label: 'Caucasian', value: 'Caucasian' },
  { label: 'Coloured', value: 'Coloured' },
  { label: 'Asian', value: 'Asian' },
  { label: 'Other', value: 'Other' }
]

const SEF_SHIFT_TYPES_OPTIONS = [
  { label: 'Participant (8x 8h per month)', value: 'Participant8x' },
  { label: 'Participant (16x 4h per month)', value: 'Participant16x' },
  { label: 'Timekeeper (unpaid)', value: 'TimekeeperUnpaid' },
  { label: 'Timekeeper (full time)', value: 'TimekeeperFullTime' },
  { label: 'Other (please notify us)', value: 'Other' }
]

const SEF_SKILL_LEVEL_OPTIONS = [
  { label: 'Skilled', value: 'Skilled' },
  { label: 'Ordinary', value: 'Ordinary' }
]

const SEF_PLATFORM_CONSTANTS = {
  SEF_BANK_ACCOUNT_TYPES,
  SEF_BANK_OPTS,
  SEF_DOCUMENT_TYPES,
  SEF_EMPLOYMENT_START_PERIODS,
  SEF_GENDER_OPTIONS,
  SEF_GOVT_GRANT_OPTIONS,
  SEF_NATIONALITY_OPTIONS,
  SEF_PURCHASE_REQUEST_STATUSES,
  SEF_PURCHASE_REQUEST_STATUS_FORM_OPTS,
  SEF_INDICATOR_NUMBER_TYPES,
  SEF_RACE_OPTIONS,
  SEF_SHIFT_TYPES_OPTIONS,
  SEF_SKILL_LEVEL_OPTIONS
}

const PERSONAL_GOAL_VISIBILITY_FORM_OPTS = [
  {
    label: 'Public - visible to everyone',
    value: 'Public',
    iconPadding: '5px',
    Icon: PublicIcon
  },
  {
    label: 'Private - only visible to you.',
    value: 'Private',
    iconPadding: '5px',
    Icon: PrivateIcon
  }
]

const PERSONAL_GOAL_VISIBILITY_OPTS = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
  ACTION_ONLY: 'ActionOnly'
}

const PERSONAL_GOAL_STATUSES = {
  ACTIVE: 'Active',
  COMPLETE: 'Complete',
  AWAITING_CHECKIN: 'AwaitingCheckin',
  MISSED_CHECKIN: 'MissedCheckin'
}

const PERSONAL_GOAL_FREQUENCIES = {
  ONCE: null,
  DAILY: 'Daily',
  WEEKDAYS: 'Weekdays',
  WEEKENDS: 'Weekends',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  ANNUALLY: 'Annually'
}

const CHALLENGE_CHECKIN_STATUSES_REVERSED = {
  Participated: 'Participated',
  DidNotParticipate: 'Did not participate'
}

const CHALLENGE_FREQUENCIES = {
  ONCE_OFF: 'once_off',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
}

const CHALLENGE_SCHEDULE_STATUSES = {
  ACTIVE: 'Active',
  COMPLETED: 'Completed',
  MISSED: 'Missed',
  SKIPPED: 'Skipped',
  WITHDREW: 'Withdrew'
}

const CHALLENGE_FREQUENCIES_REVERSED = {
  once_off: 'Once off',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly'
}

const STORY_STATES = {
  ADMIN_HIDDEN: 'AdminHidden',
  ANON_DONATION: 'AnonDonation',
  PUBLIC: 'Public',
  PRIVATE: 'Private',
  ACTION_ONLY: 'ActionOnly',
  TITLE_ONLY: 'TitleOnly'
}

const STORY_STATE_NAMES = {
  AnonDonation: 'Anonymous',
  Public: 'Public',
  Private: 'Private',
  ActionOnly: 'Action Only',
  TitleOnly: 'Title Only',
  Rejected: 'N/A',
  Withdrew: 'N/A'
}

const STORY_CONTEXT_TYPES = {
  APP: 'App',
  CAMP: 'Campaign',
  OPP: 'Opportunity',
  ORG: 'Organisation',
  USER: 'User',
  DONATION_VIA_APP: 'Application',
  PERSON_TO_ORG_DONATION: 'PersonToOrgDonation',
  ORG_TO_ORG_DONATION: 'OrgToOrgDonation',
  // deprecated
  DONATION_TO_ORG: 'DonationToOrg'
}

const STORY_CONTEXT_TYPE_NAMES = {
  App: 'APP',
  Campaign: 'Campaign',
  Opportunity: 'Cause',
  Organisation: 'Organisation',
  User: 'User',
  Application: 'Donation via Cause',
  PersonToOrgDonation: 'Personal donation',
  OrgToOrgDonation: 'Org Donation',
  // deprecated
  DonationToOrg: 'DonationToOrg'
}

const STORY_TYPES = {
  CAMP_FEATURE: 'CampFeature',
  CAMP_UPDATE: 'CampUpdate',
  CHALLENGE_CREATED: 'ChallengeCreated',
  CHALLENGE_CHECKIN: 'ChallengeCheckin',
  CHAMPION_FEATURE: 'ChampionFeature',
  DONATION_TO_ORG: 'DonationToOrg',
  DONATION_TO_ORG_CONFIRMED: 'DonationToOrgConfirmed',
  GOOD_DEED: 'GoodDeed',
  GOOD_DEED_TEMPLATE: 'GoodDeedTemplate',
  NON_PROFIT_DONATION_FEEDBACK: 'NonProfitDonationFeedback',
  NON_PROFIT_MEMBERSHIP_ADDED: 'NonProfitMembershipAdded',
  NON_PROFIT_MEMBERSHIP_DONATION_CONFIRMED: 'NonProfitMembershipDonationConfirmed',
  OPP_FEATURE: 'OppFeature',
  OPP_FEEDBACK: 'OppFeedback',
  OPP_HOST_FEEDBACK: 'OppHostFeedback',
  OPP_SIGN_UP: 'OppSignUp',
  OPP_SIGN_UP_CHALLENGE: 'OppSignUpChallenge',
  OPP_SIGN_UP_GOODS: 'OppSignUpGoods',
  OPP_SIGN_UP_GOODS_CONFIRMED: 'OppSignUpGoodsConfirmed',
  OPP_SIGN_UP_GOODS_CORPORATE: 'OppSignUpGoodsCorporate',
  OPP_SIGN_UP_GOODS_CORPORATE_CONFIRMED: 'OppSignUpGoodsCorporateConfirmed',
  OPP_SIGN_UP_MONEY: 'OppSignUpMoney',
  OPP_SIGN_UP_MONEY_CONFIRMED: 'OppSignUpMoneyConfirmed',
  OPP_SIGN_UP_MONEY_CORPORATE: 'OppSignUpMoneyCorporate',
  OPP_SIGN_UP_MONEY_CORPORATE_CONFIRMED: 'OppSignUpMoneyCorporateConfirmed',
  OPP_SIGN_UP_VOLUNTEER: 'OppSignUpVolunteer',
  OPP_CREATED: 'OppCreated',
  OPP_UPDATE: 'OppUpdate',
  ORG_FEATURE: 'OrgFeature',
  ORG_INVITE: 'OrgInvite',
  ORG_PROFILE_COMPLETE: 'OrgProfileComplete',
  ORG_UPDATE: 'OrgUpdate',
  BUG_REPORT: 'BugReport',
  SYSTEM_FEEDBACK: 'SystemFeedback',
  // DGMT
  DGMT_ADDITIONAL_WEBSITE_SUBMITTED: 'DgmtAdditionalWebsiteSubmitted',
  DGMT_PBO_LISTING: 'DgmtPboListing',
  DGMT_PBO_LISTING_CONFIRMED: 'DgmtPboListingConfirmed',
  DGMT_IMPACT_FEEDBACK: 'DgmtImpactFeedback',
  DGMT_DELISTING_FEEDBACK: 'DgmtDelistingFeedback',
  DGMT_BASE_CHARGES_FEEDBACK: 'DgmtBaseChargesFeedback',
  // SEF
  SEF_INDICATOR_OUTCOME_SUBMITTED: 'SefIndicatorOutcomeSubmitted',
  SEF_PARTNER_UPDATE: 'SefPartnerUpdate'
}

const STORY_TYPE_NAMES = {
  CampFeature: 'Campaign Featured Story',
  CampUpdate: 'Campaign Update',
  ChampionFeature: 'Chamption Featured Story',
  DgmtPboListing: 'Zero-rated website listing',
  DgmtPboListingConfirmed: 'Zero-rated website listing confirmed',
  DonationToOrg: 'Donation to Org',
  DonationToOrgConfirmed: 'Donation to Org',
  GoodDeed: 'Good Deed',
  OppFeature: 'Cause Featured Story',
  OppFeedback: 'Cause Feedback',
  OppHostFeedback: 'Cause Host Feedback',
  OppSignUp: 'Cause Signup',
  OppSignUpGoods: 'Cause Signup',
  OppSignUpMoney: 'Cause Signup',
  OppSignUpMoneyCorporate: 'Corporate Donation',
  OppSignUpChallenge: 'Cause Signup',
  OppSignUpVolunteer: 'Cause Signup',
  OppSignUpMoneyConfirmed: 'Cause Signup',
  OppSignUpMoneyCorporateConfirmed: 'Corporate Donation',
  OppCreated: 'Cause Created',
  OppUpdate: 'Cause Update',
  OrgFeature: 'Organisation Featured Story',
  OrgInvite: 'Organisation Invitation Story',
  OrgUpdate: 'Organisation Update Story'
}

const GOODS_DONATION_CONFIRMATION_OPTS = {
  CONFIRMED: 'Confirmed',
  PENDING_DONATION: 'PendingDonation',
  PENDING_DONATION_APPROVAL: 'PendingDonationApproval',
  REJECTED: 'Rejected',
  WITHDREW: 'Withdrew'
}

const GOODS_DONATION_CONFIRMATION_FORM_OPTS = [
  { label: 'Pending Approval', value: 'PendingDonationApproval' },
  { label: 'Confirmed', value: 'Confirmed' },
  { label: 'Rejected', value: 'Rejected' }
]

const DONATION_CONFIRMATION_FORM_OPTS = [
  { label: 'Pending Approval', value: 'PendingDonationApproval' },
  { label: 'Confirmed', value: 'Confirmed' },
  { label: 'Rejected', value: 'Rejected' }
]

const DONATION_DETAIL_STATES = {
  COMMITTED: 'Committed',
  PENDING_DONATION: 'PendingDonation',
  PENDING_DONATION_APPROVAL: 'PendingDonationApproval',
  CONFIRMED: 'Confirmed',
  REJECTED: 'Rejected',
  WITHDREW: 'Withdrew'
}

const DONATION_DETAIL_STATES_REVERSED = {
  Committed: 'Committed',
  Pending: 'Committed',
  PendingDonation: 'Committed',
  PendingDonationApproval: 'Pending Approval',
  Rejected: 'Rejected',
  Withdrew: 'Withdrew',
  Confirmed: 'Confirmed'
}

export default {
  ADDITIONAL_QUESTION_CONTEXTS: { SIGN_UP: 0, CHECK_IN: 1 },
  ADDITIONAL_QUESTION_TYPES: {
    document: 0,
    string_question: 1,
    integer_question: 2,
    // new values

    Text: 11,
    Integer: 12,
    MultipleChoice: 13,
    Boolean: 14,
    Location: 15,
    Document: 16,
    SingleChoice: 17
  },
  ADDITIONAL_QUESTION_TYPES_INVERSE: {
    0: 'document',
    1: 'question',
    2: 'number question',
    // new values
    11: 'question',
    12: 'number question',
    13: 'multiple choice question',
    14: 'true/false question',
    15: 'location',
    16: 'document'
  },
  APPLICATION_STATUSES: {
    Applied: 'applied',
    Notified: 'notified',
    CheckinSent: 'check-in sent',
    ProvidedFeedback: 'feedback provided',
    DidNotAttend: 'did not attend',
    Confirmed: 'confirmed',
    Rejected: 'rejected',
    Cancelled: 'cancelled'
  },
  APPLICATION_STATUS_NAMES: {
    APPLIED: 'Applied',
    NOTIFIED: 'Notified',
    CHECKIN_SENT: 'CheckinSent',
    PROVIDED_FEEDBACK: 'ProvidedFeedback',
    MARKED_AS_NON_ATTENDED: 'DidNotAttend',
    PENDING_DONATION: 'PendingDonation',
    PENDING_DONATION_APPROVAL: 'PendingDonationApproval',
    CONFIRMED: 'Confirmed',
    REJECTED: 'Rejected',
    COMMITTED: 'Committed',
    CANCELLED: 'Cancelled',
    WITHDREW: 'Withdrew'
  },
  APPLICATION_STATUS_COLUMN_NAMES: {
    JOINED: 'Joined',
    PARTICIPATED: 'Participated',
    SKIPPED: 'Skipped',
    PENDING_FEEDBACK: 'Pending feedback',
    CANCELLED: 'Cancelled'
  },
  BBBEE_LEVELS,
  BENEFACTOR_TYPES,
  BENEFICIARY_TYPES,
  CAMPAIGN_STATUSES: {
    INACTIVE: 'Inactive',
    ACTIVE: 'Active',
    COMPLETED: 'Completed'
  },
  CAMPAIGN_STATUS_NAMES: {
    Applied: 'Applied'
  },
  CAMPAIGN_URL:
    process.env.REDUX_ENV === 'production'
      ? `/${l('ROUTE_CAMPS')}/4?title=act-for-change`
      : `/${l('ROUTE_CAMPS')}/1?title=act-for-change`,
  CURRENT_YEAR: 2021,
  CUSTOM_FIELD_NAME_CONTEXTS: {
    ORG: 'Organisation',
    OPP: 'Opportunity',
    CAMP: 'Campaign',
    USER: 'User'
  },
  CUSTOM_FIELD_INPUT: {
    STRING: 'String',
    INT_DROPDOWN: 'IntDropdown',
    BOOL_DROPDOWN: 'BoolDropdown',
    INTEGER: 'Integer',
    ADDRESS: 'Address',
    PHONE: 'Phone',
    EMAIL: 'Email',
    URL: 'Url',
    STRING_DROPDOWN: 'StringDropdown'
  },
  CREATOR_CONTEXTS,
  DGMT_PBO_APPLICATION_STATUSES,
  DGMT_PBO_APPLICATION_WEBSITE_STATUSES,
  DGMT_PBO_APPLICATION_STATUS_FORM_OPTS,
  DGMT_ZERO_RATED_LISTING_STATUS_FORM_OPTS,
  DGMT_PBO_VERIFICATION_STATUS_FORM_OPTS,
  DGMT_PBO_APPLICATION_WEBSITE_STATUS_FORM_OPTS,
  DGMT_ZERO_RATING_STATUS_FORM_OPTS,
  DGMT_ZERO_RATING_STATUSES,
  DONATION_DETAIL_STATES,
  DONATION_DETAIL_STATES_REVERSED,
  DONATION_CONFIRMATION_FORM_OPTS,
  DONATION_LINK_TYPES: { OZOW: 1, EXTERNAL: 2 },
  DONO_OPT_PLATFORMS: { CUSTOM: 1, OZOW: 11, PAYPAL: 12, BACKABUDDY: 13, EFT: 14, PAYFAST: 15 },
  DONO_PLATFORM_CONTEXT_TYPES: { ORG: 'Organisation', OPP: 'Opportunity', APP: 'Application' },
  FORM_GOAL_TYPES,
  PAYROLL_REQUEST_STATUSES,
  GOODS_DONATION_STATUSES,
  GOODS_DONATION_CONFIRMATION_OPTS,
  GOODS_DONATION_CONFIRMATION_FORM_OPTS,
  HOST_CHECKIN_STATUSES: {
    CHECKIN_NOT_YET_SENT: 'CheckinNotSent',
    CHECKIN_SENT: 'CheckinSent',
    PROVIDED_FEEDBACK: 'ProvidedFeedback'
  },
  JWT_REFRESH_THRESHOLD: 60 * 20,
  MAIL_DELIVERY_STATUSES,
  MAX_FILE_SIZE: 8388608, // 8mb
  NOTIFICATION_LABELS,
  NPO_TYPES: [
    { value: 'npo', label: 'Non-Profit Company' },
    { value: 'trust', label: 'Trust' },
    { value: 'vol_assc', label: 'Voluntary Association' }
  ],
  NPO_VERIFICATION_STATUSES,
  ONBOARDING_CHOICES: { COMPLETE_SIGNUP: 1, CREATE_ORG: 2 },
  OPP_PERSONAL_CTX_OPT: {
    label: 'In a personal Capacity',
    value: 'personal',
    image: getLocalStorageValues().avatar_url || UserIcon
  },
  OPPORTUNITY_GOAL_TYPE_CTA: goalTypeCtas,
  OPPORTUNITY_GOAL_TYPE_DELIVERABLES: goalTypeDeliverables,
  OPPORTUNITY_GOAL_TYPES: goalTypes,
  OPPORTUNITY_GOAL_TYPE_NAMES: goalTypeNames,
  OPPORTUNITY_STATUSES: {
    INACTIVE: 'Inactive',
    ACTIVE: 'Active',
    CANCELLED: 'Cancelled',
    COMPLETED: 'Completed',
    DRAFT: 'Draft'
  },
  OPPORTUNITY_STATUS_NAMES: {
    Applied: 'Attending',
    Notified: 'Attending',
    Cancelled: 'Cancelled'
  },
  ORG_INVITATION_STATUSES: {
    INVITE_ACCEPTED: 'InviteAccepted',
    INVITE_AUTO_ACCEPTED: 'InviteAutoAccepted',
    INVITE_PENDING: 'InvitePending',
    INVITE_DECLINED: 'InviteDeclined',
    INVITE_RESCINDED: 'InviteRescinded'
  },
  ORG_STATUSES: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive'
  },
  OZOW_ACCOUNT_STATES: {
    PENDING: 'Pending',
    ACTIVE: 'Active',
    SUSPENDED: 'Suspended'
  },
  PAYMENT_CONFIRMATION_STATUSES: {
    PENDING: 'Pending',
    PENDING_DONATION: 'PendingDonation',
    PENDING_DONATION_APPROVAL: 'PendingDonationApproval',
    CONFIRMED: 'Confirmed'
  },
  PENDING_IMAGES: {
    UPLOAD_PENDING: 'https://cdn.browniepoints.africa/cause-defaults/pending_upload.png',
    OPP_FALLBACK: 'https://cdn.browniepoints.africa/cause-defaults/cause_fallback.png'
  },
  PERSONAL_GOAL_VISIBILITY_FORM_OPTS,
  PERSONAL_GOAL_VISIBILITY_OPTS,
  PERSONAL_GOAL_STATUSES,
  PERSONAL_GOAL_FREQUENCIES,
  PLATFORM_FEE_PERCENTAGE: 0.05,
  CHALLENGE_CHECKIN_STATUSES_REVERSED,
  CHALLENGE_FREQUENCIES,
  CHALLENGE_FREQUENCIES_REVERSED,
  CHALLENGE_SCHEDULE_STATUSES,
  REVERSE_ROLES: {
    GodAdmin: 'God admin',
    SuperAdmin: 'Superadmin',
    Admin: 'Admin',
    Member: 'Member',
    DefaultRole: 'Default role',
    OrgInviter: 'Organisation Inviter'
  },
  ROLES: {
    GOD_MODE: 'GodAdmin',
    SUPERADMIN: 'SuperAdmin',
    SUPERADMIN_PENDING: 'SuperAdminPending',
    ADMIN: 'Admin',
    MEMBER: 'Member',
    MEMBER_PENDING: 'MemberPending',
    DEFAULT: 'DefaultRole', // If there are no user roles
    ORG_INVITER: 'OrgInviter',
    // SEF
    SEF_ORG_MEMBER: 'SefOrgMember',
    SEF_EMPLOYEE: 'SefEmployee',
    SEF_INSIGHTS_REPORTER: 'SefInsightsReporter',
    SEF_PARTNER_ADMIN: 'SefPartnerAdmin',
    SEF_PARTNER_SIGNING_ADMIN: 'SefPartnerSigningAdmin',
    SEF_PARTNER_DISTRICT_FINANCE_ADMIN: 'SefPartnerDistrictFinanceAdmin',
    SEF_PARTNER_DISTRICT_OUTPUTS_ADMIN: 'SefPartnerDistrictOutputsAdmin',
    SEF_PARTNER_DISTRICT_PEOPLE_ADMIN: 'SefPartnerDistrictPeopleAdmin',
    SEF_PARTNER_FINANCE_ADMIN: 'SefPartnerFinanceAdmin',
    SEF_PAYROLL_REVIEW_ADMIN: 'SefPayrollReviewAdmin',
    SEF_PLATFORM_ADMIN: 'SefPlatformAdmin',
    SEF_WORKSITE_OUTPUTS_ADMIN: 'SefWorksiteOutputsAdmin',
    SEF_WORKSITE_PEOPLE_ADMIN: 'SefWorksitePeopleAdmin',
    SEF_WORKSPACE_ADMIN: 'SefWorkspaceAdmin',
    SEF_WORKSPACE_SIGNING_ADMIN: 'SefWorkspaceSigningAdmin',
    // DGMT
    DGMT_ADMIN: 'DgmtAdmin',
    DGMT_MNO_ADMIN: 'DgmtMnoAdmin',
    DGMT_PBO_ADMIN: 'DgmtPboAdmin',
    DGMT_ICASA_ADMIN: 'DgmtIcasaAdmin'
  },
  ROLEABLES: {
    ORG: 'Organisation',
    OPP: 'Opportunity',
    CAMP: 'Campaign',
    CATEGORY: 'Category',
    MNO: 'MobileNetworkOperator',
    SEF_PARTNER_DISTRICT: 'SefPartnerDistrict',
    SEF_PENDING_PARTNER_DISTRICT_FOR_ORG: 'SefPendingPartnerDistrictForOrg',
    SEF_PLATFORM: 'SefPlatform',
    SEF_WORKSITE: 'SefWorksite',
    SEF_WORKSPACE: 'SefWorkspace'
  },
  S3_ASSET_PATH: 'https://cdn.browniepoints.africa/assets/',
  STORY_FEED_DEFAULT_LENGTH: 50,
  STORY_STATES,
  STORY_STATE_NAMES,
  STORY_TYPES,
  STORY_TYPE_NAMES,
  STORY_CONTEXT_TYPES,
  STORY_CONTEXT_TYPE_NAMES,
  SUBSCRIPTION_COSTS: subscriptionPrices,
  SUPPORTED_IMG_FORMATS: ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'],
  USER_STATUSES: { ACCEPTED: 'accepted' },
  PLATFORM_EVENTS,
  GOOD_ECONOMY_CAMPAIGN_ID,
  WHAT_MATTERS_CAMPAIGN_ID,
  ...SEF_PLATFORM_CONSTANTS
}
