import React, { useState, useEffect } from 'react'
import { Loader, LoaderBar, LoaderBall, LoaderWrapper, Heading, SubMessage } from './styles'

const buildHeader = ({ message }) => {
  if (message) return message

  const fallbackMessage = 'Processing your request'
  return fallbackMessage
}

const AnimatedLoader = ({ fullPage = true, message }) => {
  const header = buildHeader({ message })

  const [messageIndex, setMessageIndex] = useState(0)
  const messages = [
    'This could take a couple of seconds...'
    // 'This is taking a bit longer than usual.',
    // 'Gosh, something might be wrong.'
  ]

  useEffect(() => {
    // Change the message every 3 seconds
    const timer = setInterval(() => {
      setMessageIndex(prevIndex => Math.min(prevIndex + 1, messages.length - 1))
    }, 3000)

    return () => clearInterval(timer) // Cleanup interval on unmount
  }, [messages.length])

  return (
    <LoaderWrapper fullPage={fullPage}>
      <Heading>{header}</Heading>

      <SubMessage isVisible>{messages[messageIndex]}</SubMessage>

      <Loader>
        <LoaderBar />
        <LoaderBar />
        <LoaderBar />
        <LoaderBar />
        <LoaderBar />
        <LoaderBall />
      </Loader>
    </LoaderWrapper>
  )
}

export default AnimatedLoader
