import React, { Fragment } from 'react'

// config
import constants from 'config/constants'

// utils
import checkRoles from 'utils/sef/checkRoles'
import logos from 'utils/logos'

// styles
import {
  BrowniePointsLogo,
  DashboardMobileHeading,
  DashboardHomePageMobileContainer,
  DashboardSideNavOuter,
  DashCollapseIcon,
  FooterLink,
  NavItems,
  SidebarFooterLogoWrapper
} from './styles'

// assets
import { ReactComponent as HasLocationIcon } from 'assets/images/icons/opp-location.svg'

// icons
import { AppFolder } from '@styled-icons/fluentui-system-filled/AppFolder'
import { BuildingHouse } from '@styled-icons/boxicons-regular/BuildingHouse'
import { BusinessCenter } from '@styled-icons/material-rounded/BusinessCenter'
import { Dashboard } from '@styled-icons/boxicons-solid'
import { DashboardSideNav, DashboardNavItem } from '../Dashboard'
import { DataArea } from '@styled-icons/fluentui-system-regular/DataArea'
import { NotificationsActive } from '@styled-icons/material/NotificationsActive'
import { Handshake } from '@styled-icons/fa-solid/Handshake'
import { PeopleFill as People, Receipt } from '@styled-icons/bootstrap'
import { Results } from '@styled-icons/foundation/Results'
import { Team } from '@styled-icons/remix-fill'
import { Tags } from '@styled-icons/fa-solid/Tags'
import { AdminPanelSettings } from '@styled-icons/material/AdminPanelSettings'
import { Wrench } from 'components/Icons/Icons'
import { Workspaces } from '@styled-icons/material/Workspaces'
import { Rule } from '@styled-icons/material-rounded/Rule'
import { DocumentPdf } from '@styled-icons/fluentui-system-regular/DocumentPdf'
import { FileInvoiceDollar } from '@styled-icons/fa-solid/FileInvoiceDollar'

// Load components synchronously
import ConditionalDisplay from 'components/ConditionalDisplay/ConditionalDisplay'

const { dashSidebarLogo } = logos

const checkGodRole = ({ roles = [] }) => {
  return roles.some(role => role.role_type === constants.ROLES.GOD_MODE)
}

const checkSuperAdminRole = ({ roles = [] }) => {
  return roles.some(r => {
    return r.roleable === constants.ROLEABLES.ORG && r.role_type === constants.ROLES.SUPERADMIN
  })
}

const determineAttendancePermissions = ({
  workspaceDetails,
  hasGodRole,
  isPartnerAdmin,
  isSuperAdmin,
  isWorkspaceAdmin
}) => {
  if (hasGodRole) return true

  const firstWorkspace = workspaceDetails[0]
  if (!firstWorkspace) return false

  const featureEnabled = firstWorkspace.attendances_enabled
  if (!featureEnabled) return false

  if (isWorkspaceAdmin) return true
  if (isPartnerAdmin) return true
  if (isSuperAdmin) return true

  return false
}

const determineBudgetCategoriesPermissions = ({
  workspaceDetails,
  hasGodRole,
  isWorkspaceAdmin
}) => {
  if (hasGodRole) return true

  const firstWorkspace = workspaceDetails[0]
  if (!firstWorkspace) return false

  const featureEnabled = firstWorkspace.purchase_requests_enabled
  if (!featureEnabled) return false

  return isWorkspaceAdmin
}

const determinePurchasesPermissions = ({
  workspaceDetails = [],
  hasGodRole,
  isPartnerAdmin,
  isWorkspaceAdmin
}) => {
  if (hasGodRole) return true

  const firstWorkspace = workspaceDetails[0]
  if (!firstWorkspace) return false

  const featureEnabled = firstWorkspace.purchase_requests_enabled
  if (!featureEnabled) return false

  if (isWorkspaceAdmin) return true
  if (isPartnerAdmin) return true

  return false
}

const determineSuppliersPermissions = ({
  workspaceDetails = [],
  hasGodRole,
  isPartnerAdmin,
  isWorkspaceAdmin
}) => {
  if (hasGodRole) return true

  const firstWorkspace = workspaceDetails[0]
  if (!firstWorkspace) return false

  const featureEnabled = firstWorkspace.purchase_requests_enabled
  if (!featureEnabled) return false

  if (isWorkspaceAdmin) return true
  if (isPartnerAdmin) return true

  return false
}

const determineOutputAreaPermissions = ({
  workspaceDetails = [],
  hasGodRole,
  isWorkspaceAdmin
}) => {
  if (hasGodRole) return true

  const firstWorkspace = workspaceDetails[0]
  if (!firstWorkspace) return false

  const featureEnabled =
    firstWorkspace.district_outputs_enabled || firstWorkspace.worksite_outputs_enabled
  if (!featureEnabled) return false

  return isWorkspaceAdmin
}

const determineOutputsPermissions = ({
  workspaceDetails = [],
  hasGodRole,
  isPartnerAdmin,
  isPartnerDistrictOutputsAdmin,
  isSuperAdmin,
  isWorksiteOutputsAdmin,
  isWorkspaceAdmin
}) => {
  if (hasGodRole) return true

  const firstWorkspace = workspaceDetails[0]
  if (!firstWorkspace) return false

  const featureEnabled =
    firstWorkspace.district_outputs_enabled || firstWorkspace.worksite_outputs_enabled
  if (!featureEnabled) return false

  return (
    isWorkspaceAdmin ||
    isPartnerAdmin ||
    isWorksiteOutputsAdmin ||
    isPartnerDistrictOutputsAdmin ||
    isSuperAdmin
  )
}

const determineParticipantPermissions = ({
  isPartnerDistrictPeopleAdmin,
  hasGodRole,
  isPartnerAdmin,
  isSuperAdmin,
  isWorksitePeopleAdmin,
  isWorkspaceSigningAdmin,
  isWorkspaceAdmin,
  workspaceDetails
}) => {
  if (hasGodRole) return true

  const firstWorkspace = workspaceDetails[0]
  if (!firstWorkspace) return false

  const featureEnabled = firstWorkspace.participant_management_enabled
  if (!featureEnabled) return false

  return (
    isPartnerDistrictPeopleAdmin ||
    isSuperAdmin ||
    isPartnerAdmin ||
    isWorkspaceSigningAdmin ||
    isWorkspaceAdmin ||
    isWorksitePeopleAdmin
  )
}

const determinePayrollPermissions = ({ workspaceDetails = [], hasGodRole, isWorkspaceAdmin }) => {
  if (hasGodRole) return true

  const firstWorkspace = workspaceDetails[0]
  if (!firstWorkspace) return false

  // const featureEnabled = firstWorkspace.payroll_enabled
  // if (!featureEnabled) return false

  return isWorkspaceAdmin
}

const determineAgreementPermissions = ({
  workspaceDetails = [],
  hasGodRole,
  isWorkspaceAdmin,
  isWorkspaceSigningAdmin
}) => {
  if (hasGodRole) return true

  const firstWorkspace = workspaceDetails[0]
  if (!firstWorkspace) return false

  const featureEnabled = firstWorkspace.participant_management_enabled
  if (!featureEnabled) return false

  return isWorkspaceAdmin || isWorkspaceSigningAdmin
}

const determineWorksitesPermissions = ({
  workspaceDetails = [],
  hasGodRole,
  isPartnerAdmin,
  isSuperAdmin,
  isWorksiteOutputsAdmin,
  isWorksitePeopleAdmin,
  isWorkspaceAdmin
}) => {
  if (hasGodRole) return true

  const firstWorkspace = workspaceDetails[0]
  if (!firstWorkspace) return false

  const featureEnabled =
    firstWorkspace.district_outputs_enabled ||
    firstWorkspace.worksite_outputs_enabled ||
    firstWorkspace.participant_management_enabled ||
    firstWorkspace.attendances_enabled ||
    true // temporary so SEF SIPs can see worksites
  if (!featureEnabled) return false

  return (
    isWorkspaceAdmin ||
    isPartnerAdmin ||
    isWorksiteOutputsAdmin ||
    isWorksitePeopleAdmin ||
    isSuperAdmin
  )
}

const determineOutputIndicatorPermissions = ({
  workspaceDetails = [],
  hasGodRole,
  isWorkspaceAdmin
}) => {
  if (hasGodRole) return true

  const firstWorkspace = workspaceDetails[0]
  if (!firstWorkspace) return false

  const featureEnabled =
    firstWorkspace.district_outputs_enabled || firstWorkspace.worksite_outputs_enabled
  if (!featureEnabled) return false

  return isWorkspaceAdmin
}

const determineDistrictsPermissions = ({
  workspaceDetails,
  hasGodRole,
  isPartnerAdmin,
  isPartnerDistrictOutputsAdmin,
  isWorkspaceAdmin
}) => {
  if (hasGodRole) return true

  const firstWorkspace = workspaceDetails[0]
  if (!firstWorkspace) return false

  const featureEnabled =
    firstWorkspace.district_outputs_enabled ||
    firstWorkspace.worksite_outputs_enabled ||
    firstWorkspace.participant_management_enabled
  if (!featureEnabled) return false

  if (isWorkspaceAdmin) return true
  if (isPartnerAdmin) return true
  if (isPartnerDistrictOutputsAdmin) return true

  return false
}

const SefSidebar = ({
  fetchNotifications,
  hasFetchedRoles,
  locationInfo,
  notifications,
  roles = [],
  sidebarIsCollapsed,
  toggleSidebarCollapse,
  workspaceDetails = []
}) => {
  const hasGodRole = checkGodRole({ roles })
  const isSuperAdmin = checkSuperAdminRole({ roles })
  const {
    isPartnerDistrictPeopleAdmin,
    isPartnerAdmin,
    isPlatformAdmin,
    isPartnerDistrictOutputsAdmin,
    isWorksitePeopleAdmin,
    isWorksiteOutputsAdmin,
    isWorkspaceSigningAdmin,
    isWorkspaceAdmin
  } = checkRoles({
    roleData: roles
  })

  const canViewAttendance = determineAttendancePermissions({
    workspaceDetails,
    hasGodRole,
    isPartnerAdmin,
    isSuperAdmin,
    isWorkspaceAdmin
  })
  const canViewBudgetCategories = determineBudgetCategoriesPermissions({
    workspaceDetails,
    hasGodRole,
    isWorkspaceAdmin
  })
  const canViewPurchases = determinePurchasesPermissions({
    workspaceDetails,
    hasGodRole,
    isPartnerAdmin,
    isWorkspaceAdmin
  })
  const canViewSuppliers = determineSuppliersPermissions({
    workspaceDetails,
    hasGodRole,
    isPartnerAdmin,
    isWorkspaceAdmin
  })
  const canViewDistricts = determineDistrictsPermissions({
    workspaceDetails,
    hasGodRole,
    isPartnerAdmin,
    isPartnerDistrictOutputsAdmin,
    isWorkspaceAdmin
  })
  const canViewOutputAreas = determineOutputAreaPermissions({
    workspaceDetails,
    hasGodRole,
    isWorkspaceAdmin
  })
  const canViewOutputIndicators = determineOutputIndicatorPermissions({
    workspaceDetails,
    hasGodRole,
    isWorkspaceAdmin
  })
  const canViewOutputs = determineOutputsPermissions({
    workspaceDetails,
    hasGodRole,
    isPartnerAdmin,
    isPartnerDistrictOutputsAdmin,
    isSuperAdmin,
    isWorksiteOutputsAdmin,
    isWorkspaceAdmin
  })
  const canViewWorksites = determineWorksitesPermissions({
    workspaceDetails,
    hasGodRole,
    isPartnerAdmin,
    isSuperAdmin,
    isWorksiteOutputsAdmin,
    isWorksitePeopleAdmin,
    isWorkspaceAdmin
  })
  const canViewParticipants = determineParticipantPermissions({
    workspaceDetails,
    hasGodRole,
    isPartnerDistrictPeopleAdmin,
    isPartnerAdmin,
    isSuperAdmin,
    isWorkspaceSigningAdmin,
    isWorkspaceAdmin
  })
  const canViewPayroll = determinePayrollPermissions({
    workspaceDetails,
    hasGodRole,
    isWorkspaceAdmin
  })
  const canViewAgreements = determineAgreementPermissions({
    workspaceDetails,
    hasGodRole,
    isWorkspaceAdmin,
    isWorkspaceSigningAdmin
  })

  const canViewPartners = hasGodRole || isWorkspaceAdmin
  const canViewAdmins =
    hasGodRole || isSuperAdmin || isPartnerAdmin || isWorkspaceAdmin || isWorkspaceSigningAdmin

  const canViewWorkspaces = hasGodRole || isPlatformAdmin

  if (!hasFetchedRoles) {
    return (
      <Fragment>
        <DashboardHomePageMobileContainer displayMobile={locationInfo.homeActive}>
          <DashboardMobileHeading>Dashboard Menu</DashboardMobileHeading>
        </DashboardHomePageMobileContainer>

        <DashboardSideNavOuter displayMobile={locationInfo.homeActive}>
          <DashboardSideNav displayMobile={locationInfo.homeActive}>
            <NavItems id="dashboard-menu-items-wrapper" displayMobile={locationInfo.homeActive}>
              <DashboardNavItem
                hiddenOnMobile={locationInfo.homeActive}
                Icon={Dashboard}
                hover="exception"
                id={locationInfo.homeActive ? 'active-dash-nav-item' : undefined}
                isActive={locationInfo.homeActive}
                label="Dashboard"
                to="/dashboard"
              />
            </NavItems>
          </DashboardSideNav>

          <SidebarFooterLogoWrapper>
            <FooterLink to="/">
              <BrowniePointsLogo src={dashSidebarLogo} />
            </FooterLink>
          </SidebarFooterLogoWrapper>
        </DashboardSideNavOuter>
      </Fragment>
    )
  }

  const hasAnyNotifications = notifications.filter(n => n.status === 'Unread').length

  return (
    <Fragment>
      <DashboardHomePageMobileContainer displayMobile={locationInfo.homeActive}>
        <DashboardMobileHeading>Dashboard Menu</DashboardMobileHeading>
      </DashboardHomePageMobileContainer>

      <DashboardSideNavOuter
        displayMobile={locationInfo.homeActive}
        sidebarIsCollapsed={sidebarIsCollapsed}
      >
        <DashboardSideNav
          displayMobile={locationInfo.homeActive}
          sidebarIsCollapsed={sidebarIsCollapsed}
        >
          <NavItems
            id="dashboard-menu-items-wrapper"
            displayMobile={locationInfo.homeActive}
            sidebarIsCollapsed={sidebarIsCollapsed}
          >
            <DashboardNavItem
              hiddenOnMobile={locationInfo.homeActive}
              Icon={Dashboard}
              hover="exception"
              id={locationInfo.homeActive ? 'active-dash-nav-item' : undefined}
              isActive={locationInfo.homeActive}
              label="Dashboard"
              sidebarIsCollapsed={sidebarIsCollapsed}
              to="/dashboard"
            />

            <DashboardNavItem
              hover="exception"
              Icon={NotificationsActive}
              id={locationInfo.notificationsActive ? 'active-dash-nav-item' : undefined}
              isActive={locationInfo.notificationsActive}
              label="Notifications"
              onClick={() => fetchNotifications()}
              showNotificationDot={hasAnyNotifications}
              sidebarIsCollapsed={sidebarIsCollapsed}
              to={'/dashboard/notifications'}
            />

            <ConditionalDisplay displayWhen={[canViewWorkspaces]}>
              <DashboardNavItem
                hover="exception"
                Icon={Workspaces}
                isActive={locationInfo.workspacesActive}
                id={locationInfo.workspacesActive ? 'active-dash-nav-item' : undefined}
                label="Workspaces"
                sidebarIsCollapsed={sidebarIsCollapsed}
                to={'/dashboard/workspaces'}
              />
            </ConditionalDisplay>

            {false && (
              <DashboardNavItem
                label="Rules"
                Icon={Rule}
                to={'/dashboard/rules'}
                isActive={locationInfo.rulesActive}
                hover="exception"
                id={locationInfo.rulesActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            )}

            <ConditionalDisplay displayWhen={[canViewAgreements]}>
              <DashboardNavItem
                label="Agreements"
                Icon={DocumentPdf}
                to={'/dashboard/agreements'}
                isActive={locationInfo.agreementsActive}
                hover="exception"
                id={locationInfo.agreementsActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[canViewPartners]}>
              <DashboardNavItem
                label="Partners"
                Icon={Handshake}
                to={'/dashboard/partners'}
                isActive={locationInfo.partnersActive}
                hover="exception"
                id={locationInfo.partnersActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[canViewDistricts]}>
              <DashboardNavItem
                label="Districts"
                Icon={HasLocationIcon}
                to={'/dashboard/districts'}
                isActive={locationInfo.districtsActive}
                id={locationInfo.districtsActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[canViewWorksites]}>
              <DashboardNavItem
                label="Worksites"
                Icon={BuildingHouse}
                to={'/dashboard/worksites'}
                isActive={locationInfo.worksitesActive}
                id={locationInfo.worksitesActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[canViewAdmins]}>
              <DashboardNavItem
                label="Admins"
                Icon={AdminPanelSettings}
                hover="exception"
                to={'/dashboard/admins'}
                isActive={locationInfo.adminsActive}
                id={locationInfo.adminsActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[canViewParticipants]}>
              <DashboardNavItem
                label="Participants"
                Icon={People}
                hover="exception"
                to={'/dashboard/participants'}
                isActive={locationInfo.participantsActive}
                id={locationInfo.participantsActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[canViewAttendance]}>
              <DashboardNavItem
                label="Attendance"
                Icon={Team}
                to={'/dashboard/attendance'}
                isActive={locationInfo.attendanceActive}
                hover="exception"
                id={locationInfo.attendanceActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[canViewOutputs]}>
              <DashboardNavItem
                label="Outputs"
                Icon={Results}
                to={'/dashboard/outputs'}
                isActive={locationInfo.outputsActive}
                hover="exception"
                id={locationInfo.outputsActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[canViewOutputAreas]}>
              <DashboardNavItem
                label="Areas"
                hover="exception"
                Icon={AppFolder}
                to={'/dashboard/output-areas'}
                isActive={locationInfo.outputAreasActive}
                id={locationInfo.outputAreasActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[canViewOutputIndicators]}>
              <DashboardNavItem
                label="Indicators"
                hover="exception"
                Icon={DataArea}
                to={'/dashboard/output-indicators'}
                isActive={locationInfo.outputIndicatorsActive}
                id={locationInfo.outputIndicatorsActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[canViewSuppliers]}>
              <DashboardNavItem
                label="Suppliers"
                Icon={BusinessCenter}
                to={'/dashboard/suppliers'}
                isActive={locationInfo.suppliersActive}
                hover="exception"
                id={locationInfo.suppliersActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[canViewBudgetCategories]}>
              <DashboardNavItem
                label="Budgets"
                hover="exception"
                Icon={Tags}
                to={'/dashboard/budget-categories'}
                isActive={locationInfo.budgetCategoriesActive}
                id={locationInfo.budgetCategoriesActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[canViewPayroll]}>
              <DashboardNavItem
                label="Payroll"
                Icon={FileInvoiceDollar}
                to={'/dashboard/payroll'}
                isActive={locationInfo.payrollActive}
                hover="exception"
                id={locationInfo.payrollActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <ConditionalDisplay displayWhen={[canViewPurchases]}>
              <DashboardNavItem
                label="Purchases"
                Icon={Receipt}
                to={'/dashboard/purchase-requests'}
                isActive={locationInfo.purchaseRequestsActive}
                hover="exception"
                id={locationInfo.purchaseRequestsActive ? 'active-dash-nav-item' : undefined}
                sidebarIsCollapsed={sidebarIsCollapsed}
              />
            </ConditionalDisplay>

            <DashboardNavItem
              label="Account"
              hover="exception"
              Icon={Wrench}
              to={'/dashboard/account'}
              isActive={locationInfo.accountActive}
              id={locationInfo.accountActive ? 'active-dash-nav-item' : undefined}
              sidebarIsCollapsed={sidebarIsCollapsed}
            />
          </NavItems>
        </DashboardSideNav>

        <DashCollapseIcon
          sidebarIsCollapsed={sidebarIsCollapsed}
          toggleSidebarCollapse={toggleSidebarCollapse}
        />
      </DashboardSideNavOuter>
    </Fragment>
  )
}

export default SefSidebar
